import { SharedoFetch } from '@sharedo/mobile-core'

function getChronology(sharedoId, rpp, p)
{
    var request = {
        rowsPerPage: rpp,
        startPage: p,
        endPage: p,
        mode: "sharedo",
        id: sharedoId,
        includeCaseOnlyParticipant: false,
        includeChildren: true,
        showPrivate: true,
    };

    // TODO public API
    return SharedoFetch.post(`/api/chronology/_search`, request);
}

export default 
{
    getChronology,
};
