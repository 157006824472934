<template>
    <v-container>
        <VTopToolbar title="Chronology" :showBack="true"></VTopToolbar>

        <div v-show="loading">
            <v-skeleton-loader type="list-item-avatar@5"></v-skeleton-loader>
        </div>

        <h4
            class="grey-text font-weight-light mt-3 mb-5"
            v-if="!loading && !chronology.length"
        >
            There are no chronology events.
        </h4>

        <v-timeline
            align-top
            dense
            style="margin-left: -12px;"
            v-for="(item, index) in chronology"
            :key="item.id"
        >
            <v-timeline-item hide-dot  v-if="!sameDayAs(item, chronology[index - 1])">
                <v-subheader class="px-0" style="height:unset">{{item.createdDay}}</v-subheader>
            </v-timeline-item>

            <v-timeline-item 
                :color="item.poiTypeColour"
                :icon="item.poiTypeIcon"
                fill-dot
            >
                <v-row>
                    <v-col cols="2">
                        <span class="">{{item.created.format('HH:mm')}}</span>
                    </v-col>
                    <v-col>
                        <strong>{{item.title}}</strong>
                        <div class="text-caption" v-if="item.notes">{{item.notes}}</div>
                        <div class="text-caption pt-1" v-if="item.userFullName">
                            <v-icon small style="vertical-align: text-bottom;">mdi-account</v-icon>
                            {{item.userFullName}}
                        </div>
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-timeline>

        <v-btn
            outlined
            rounded
            block
            class="mt-4"
            color="primary"
            v-show="!loading && hasMore"
            :loading="loadingMore"
            @click.stop="loadNextPage()"
        >
            More
        </v-btn>
    </v-container>
</template>

  
<script>
import chronologyAgent from "./chronologyAgent.js";
import chronologyMapper from "./chronologyMapper.js";

const rpp = 10;

// TODO move component to core?
export default {
    data() {
        return {
            sharedoId: null,
            loading: true,
            loadingMore: false,
            chronology: [],
            lastPageLoaded: 0,
            hasMore: true,
        };
    },
    mounted() {
        this.sharedoId = this.$route.params.sharedoId;

        this.refresh();
    },
    methods: {
        loadPage() {
            return new Promise((resolve, reject) => {
                this.lastPageLoaded++;

                chronologyAgent
                    .getChronology(this.sharedoId, rpp, this.lastPageLoaded)
                    .then((data) => {
                        this.loading = false;

                        var models = chronologyMapper.mapChronology(data.rows);

                        models.forEach(m => {

                            // Enrich
                            m.createdDay = this.toCalendarDate(m.created);

                            this.chronology.push(m);
                        });

                        var totalPages = Math.ceil(data.totalRows / rpp);
                        var hasMore = totalPages > this.lastPageLoaded;

                        resolve({ hasMore: hasMore });
                    })
                    .catch(console.error);
            });
        },

        toCalendarDate(date) {
            return date.calendar(null, {
                lastDay : '[Yesterday]',
                sameDay : '[Today]',
                lastWeek : 'dddd, DD MMMM',
                sameElse : 'L'
            });
        },

        sameDayAs(item, previous) {
            return previous && item.createdDay === previous.createdDay;
        },

        loadNextPage() {
            this.loadingMore = true;
            this.loadPage()
                .then((result) => {
                    this.loadingMore = false;
                    if (!result.hasMore) {
                        this.hasMore = false;
                    }
                })
                .catch(console.error);
        },

        refresh() {
            this.chronology = [];
            this.lastPageLoaded = 0;
            this.hasMore = true;

            this.loadPage()
                .then((result) => {
                    this.hasMore = result.hasMore;
                })
                .catch(console.error);
        },
    },
};
</script>

<style>
/* Give body more space */
.v-timeline-item__divider {
    min-width: 56px;
}
.v-timeline--dense .v-timeline-item__body {
    max-width: calc(100% - 77px);
}

.v-timeline-item__dot {
    margin-top: -7px;
}
.v-timeline-item__dot .v-icon {
    font-size: 18px;
}
</style>
